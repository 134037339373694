import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import styled, { keyframes } from 'styled-components';

import { books } from '@constants/book-carousel-data';
import { brand, fontMedium } from '@constants/colors';
import { tablet } from '@constants/media-queries';
import { bookChoice } from '@constants/routes';
import { space } from '@constants/spaces';

const bookWidth = 12;

// Keyframe for seamless scrolling
const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-${bookWidth}% * ${books.length}));
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Track = styled.div`
  display: flex;
  animation: ${scroll} 50s linear infinite;
  width: calc(${bookWidth}% * ${books.length * 2});
  box-sizing: border-box;

  ${CarouselContainer}:hover & {
    animation-play-state: paused;
  }

  ${tablet} {
    animation: ${scroll} 90s linear infinite;
  }
`;

const Slide = styled.div`
  flex: 0 0 ${bookWidth * 1.25}%;
  padding: 0 ${space * 0.5}px;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    transition: transform 0.3s ease;
  }

  &:hover {
    img {
      transform: scale(1.02);
    }
  }

  ${tablet} {
    flex: 0 0 ${bookWidth}%;
  }
`;

const Title = styled.p`
  font-size: ${space * 2}px;
  font-weight: 600;
  color: white;
  font-size: 13px;
  background-color: ${brand};
  padding: ${space * 0.5}px ${space * 1.5}px;
  display: inline-block;
  text-align: center;
  border-radius: 16px;

  ${tablet} {
    font-size: 13px;
  }
`;

// Duplicate books for seamless scroll
const doubledBooks = [...books, ...books];

const BookCarousel = () => {
  const router = useRouter();

  return (
    <CarouselContainer>
      <Track>
        {doubledBooks.map((book, index) => (
          <Slide key={index} onClick={() => router.push(bookChoice)}>
            <Image
              src={book.image}
              alt={book.title}
              priority
              width={300}
              height={300}
              style={{ width: '100%', height: 'auto' }}
            />
            <Title style={{ backgroundColor: book.color }}>{book.title}</Title>
          </Slide>
        ))}
      </Track>
    </CarouselContainer>
  );
};

export default BookCarousel;
