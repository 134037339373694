import { useRouter } from 'next/router';
import styled from 'styled-components';

import { brand, fontDark, fontMedium } from '@constants/colors';
import { tablet } from '@constants/media-queries';
import { prices } from '@constants/routes';
import { space } from '@constants/spaces';

const Component = styled.div`
  background-color: rgba(0, 0, 0, 0.04);
  padding: ${space * 2}px ${space * 4}px;
  margin: ${space * 5}px 0 ${space * 3}px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  border-radius: 32px;
  color: ${fontMedium};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${space * 3}px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  ${tablet} {
    flex-direction: row;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.07);
    color: ${fontDark};
  }
`;

const UspText = styled.span`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    transform: translateY(-50%);
    top: 140%;
    left: 50%;
    height: 4px;
    width: 4px;
    background-color: ${brand};
    border-radius: 50%;

    ${tablet} {
      top: 50%;
      left: auto;
      transform: translateY(-50%);
      margin-left: ${space * 1.3}px;
    }
  }

  &:last-child::after {
    display: none;
  }
`;

const UspBar = () => {
  const router = useRouter();

  return (
    <>
      <Component onClick={() => router.push(prices)}>
        <UspText>Vanaf €27,50</UspText>
        <UspText>Binnen 5 werkdagen verzonden</UspText>
        <UspText>Hoge kwaliteit</UspText>
      </Component>
    </>
  );
};

export default UspBar;
