import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { fontMedium } from '@constants/colors';
import { space } from '@constants/spaces';
import Info from '@elements/Info';
import Toggle from '@elements/Toggle';
import { useBookToggleViewableSpreadsMutation } from '@graphql/generated/graphql';
import { useBookContext } from '@hooks/useBookContext';

const Container = styled.div`
  padding: ${space * 2}px 0;
  margin-top: ${space * 4}px;
  display: flex;
  align-items: center;
  gap: ${space * 3}px;
  justify-content: space-between;

  h4 {
    color: ${fontMedium};
  }
`;

const Form = styled.form`
  height: 32px;
  display: flex;
  align-items: center;
`;

const ParticipantPrivacySettings = ({ bookId }: { bookId: string }) => {
  const formMethods = useForm();
  const { book } = useBookContext();
  const [BookToggleViewableSpreadsMutation] =
    useBookToggleViewableSpreadsMutation({
      variables: { bookID: bookId },
    });

  const onToggle = () => {
    BookToggleViewableSpreadsMutation({
      variables: {
        bookID: bookId,
      },
    });
  };

  return (
    <Container>
      <h4>
        Deelnemers mogen elkaars pagina’s bekijken
        <Info position="top">
          Bewerken blijft alleen mogelijk voor eigen pagina’s. Je kunt deze
          instelling ook later aan- of uitzetten, bijvoorbeeld als het boek af
          is.
        </Info>
      </h4>
      <FormProvider {...formMethods}>
        <Form>
          <Toggle
            name="toggle"
            defaultChecked={book.viewableSpreads}
            onChange={onToggle}
            bookId={bookId as string}
          />
        </Form>
      </FormProvider>
    </Container>
  );
};

export default ParticipantPrivacySettings;
