import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import {
  backgroundColoredMedium,
  brand,
  cta,
  ctaOutline,
  fontDark,
  fontMedium,
  primary300,
} from '@constants/colors';
import { tablet } from '@constants/media-queries';
import { bookChoice, prices } from '@constants/routes';
import { contentWidth } from '@constants/sizes';
import { space } from '@constants/spaces';
import Badge from '@elements/Badge';
import Text from '@elements/Text';
import Title from '@elements/Title';

import ScrollLink from './ScrollLink';

const Component = styled.section`
  padding: ${space * 5}px ${space * 4}px;
  text-align: center;
  color: ${fontDark};
  background-color: ${backgroundColoredMedium};

  ${tablet} {
    padding: ${space * 8}px ${space * 4}px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: ${contentWidth};
`;

const StyledText = styled(Text)`
  color: ${fontMedium};
  font-weight: 500;

  span {
    padding: 0 4px;
    opacity: 0.5;
  }
`;

const StyledLink = styled.a`
  color: ${cta};
  font-weight: 500;

  svg {
    margin-left: ${space}px;
  }

  &:hover {
    color: ${ctaOutline};
  }
`;

const Info = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${space * 3}px;
`;

const StyledBadge = styled(Badge)`
  border-radius: 8px;
  background-color: ${brand};
  font-weight: 600;
  color: white;
  font-size: 12px;
  padding: ${space / 4}px ${space}px;
  cursor: pointer;
`;

const ImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${space}px;
  cursor: pointer;
`;

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${space * 2}px;
  margin-top: ${space * 3}px;
  flex-wrap: wrap;

  ${tablet} {
    flex-wrap: nowrap;
  }
`;

const BookMedium = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Pricing = styled.div`
  margin: ${space}px auto;
  font-size: 14px;
  font-weight: 500;
  color: ${brand};
  text-align: center;
  background-color: ${primary300};
  padding: 2px ${space * 1.5}px;
  border-radius: 10px;
  span {
    font-weight: 800;
    font-size: 16px;
  }
`;

const SizeInfo = styled(Text)`
  margin: ${space * 2}px 0 ${space * 0.5}px;
  font-weight: 500;
  color: ${fontMedium};
`;

type BookTypesProps = {
  main?: boolean;
  buttonText?: string;
  buttonLink?: string;
};

const BookTypes = ({ main, buttonText, buttonLink }: BookTypesProps) => {
  return (
    <Component>
      <Container>
        <Info>
          <ScrollLink pagePath="/prijzen" targetId="levertijden" offset={-100}>
            <StyledBadge>Verzonden binnen 5 werkdagen!</StyledBadge>
          </ScrollLink>
        </Info>
        {main ? (
          <Title size="lg" weight={800} className="mb-8" as="h1">
            Prijzen & levertijd
          </Title>
        ) : (
          <Title size="lg" weight={800} className="mb-8" as="h2">
            Kies zelf het formaat
          </Title>
        )}
        <Text size="nm" className="mb-24">
          Pak groots uit of geef een handzaam cadeau. Je kiest pas als je het
          boek bestelt.
        </Text>
        <Link href={buttonLink || prices} passHref>
          <StyledLink>
            {buttonText || 'Bekijk prijzen'}
            <FontAwesomeIcon icon={faArrowRightLong} />
          </StyledLink>
        </Link>
        <Link href={bookChoice} passHref>
          <ImgContainer>
            <BookMedium>
              <ImgWrapper>
                <Image
                  src="https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/size_bottle_uJ0bwMCu7.png?updatedAt=1734616388636&ik-s=e48720fa7180d2d15385eb362deaa70a4262bc82"
                  alt="Boek Formaat 1"
                  width={80}
                  height={300}
                  unoptimized={true}
                  loading="lazy"
                />
              </ImgWrapper>
              <ImgWrapper>
                <Image
                  src="https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/size_book_medium_pUTtHTQwN.png?updatedAt=1734616388664&ik-s=29ced5822b88d6b797e704ab797ad1d1272e2213"
                  alt="Boek formaat middel"
                  width={220}
                  height={300}
                  unoptimized={true}
                  loading="lazy"
                />
                <SizeInfo size="sm">Middel 21 x 21cm</SizeInfo>
                <Pricing>
                  Vanaf <span>€27,50</span>
                </Pricing>
              </ImgWrapper>
            </BookMedium>
            <ImgWrapper>
              <Image
                src="https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/size_book_large_wekaoWVtW.png?updatedAt=1734616388710&ik-s=ed57304940891063b2915d5101167419a1c0a3e4"
                alt="Boek formaat groot"
                width={300}
                height={300}
                unoptimized={true}
                loading="lazy"
              />
              <SizeInfo size="sm">Groot 30 x 30cm</SizeInfo>
              <Pricing>
                Vanaf <span>€37,50</span>
              </Pricing>
            </ImgWrapper>
          </ImgContainer>
        </Link>
        <>
          <StyledText size="sm" className="mt-24">
            24-400 pagina&apos;s <span>●</span> Vanaf €0,50 per extra pagina
          </StyledText>
          <StyledText size="sm" className="mt-8">
            Verzendkosten € 3,50 <span>(Nederland)</span>
          </StyledText>
        </>
      </Container>
    </Component>
  );
};

export default BookTypes;
