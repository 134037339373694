import {
  faArrowRightLong,
  faMinus,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import styled from 'styled-components';

import { cta, ctaOutline, fontDark } from '@constants/colors';
import { tablet } from '@constants/media-queries';
import { faq, prices } from '@constants/routes';
import { space } from '@constants/spaces';
import Accordion from '@elements/Accordion';
import Text from '@elements/Text';
import Title from '@elements/Title';

const Component = styled.section`
  padding: ${space * 5}px ${space * 3}px;
  text-align: center;
  color: ${fontDark};

  ${tablet} {
    padding: ${space * 8}px ${space * 4}px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 600px;
`;

const StyledLink = styled.a`
  color: ${cta};
  font-weight: 500;

  svg {
    margin-left: ${space}px;
  }

  &:hover {
    color: ${ctaOutline};
  }
`;

const Questions = styled.div`
  margin: ${space * 5}px 0;
  text-align: left;

  h4 {
    padding: ${space}px 0;
  }

  svg {
    height: 20px;
    width: 20px;
  }
`;

const QContainer = styled.div`
  padding: ${space * 2}px 0;
  border-top: 1px solid #f1f2f4;

  &:last-of-type {
    border-bottom: 1px solid #f1f2f4;
  }
`;

const questionData = [
  {
    title: 'Met hoeveel mensen kan ik een fotoboek maken?',
    description:
      'Een boek kan tot 400 pagina’s bevatten. Als je daar de 1e en laatste pagina van afhaalt houd je er 398 over. Iedere deelnemer heeft altijd minimaal 2 pagina’s. Als je 398 deelt door 2 kom je uit op maximaal 199 deelnemers!',
  },
  {
    title: 'Wat kost een fotoboek?',
    description: (
      <>
        De prijs van een boek is afhankelijk van het formaat (middel of groot),
        het soort papier (normaal of vlakliggend) en het aantal pagina’s. Op de{' '}
        <Link href={prices} passHref>
          <StyledLink>prijzenpagina</StyledLink>
        </Link>{' '}
        vind je een overzicht van de prijzen!
      </>
    ),
  },
  {
    title: 'Wat is de levertijd van een fotoboek?',
    description:
      'We versturen je boek binnen 5 werkdagen. Tijdens Nationale Feestdagen leveren we niet, houd in die gevallen rekening met 1 of 2 extra dagen.',
  },
];

const GoodToKnow = () => {
  return (
    <Component>
      <Container>
        <Title size="lg" weight={800} as="h2">
          Handig om te weten
        </Title>
        <Questions>
          {questionData.map((question, index) => (
            <QContainer key={index}>
              <Accordion iconOpen={faMinus} iconClosed={faPlus}>
                <Title size="xs" weight={800} as="h4">
                  {question.title}
                </Title>
                <Text size="nm" className="ml-8">
                  {question.description}
                </Text>
              </Accordion>
            </QContainer>
          ))}
        </Questions>
        <Link href={faq} passHref>
          <StyledLink target="_blank" rel="noopener noreferrer">
            Bekijk alle vragen en antwoorden
            <FontAwesomeIcon icon={faArrowRightLong} />
          </StyledLink>
        </Link>
      </Container>
    </Component>
  );
};

export default GoodToKnow;
