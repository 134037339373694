import {
  faFacebookMessenger,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lighten } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { INVITE_MESSAGE } from '@constants/invite-message';
import { space } from '@constants/spaces';
import Button from '@elements/Button';

const ActionsContainer = styled.div`
  display: flex;
  gap: ${space * 3}px;
  justify-content: center;
  margin: 0 0 ${space * 3}px;
`;

const StyledButton = styled(Button)`
  height: 56px;
  width: 56px;
  border-radius: 50%;
  border: none;

  svg {
    padding-top: 3px;
    font-size: 24px;
  }

  &:nth-of-type(1) {
    background-color: ${lighten(0.43, '#25D366')};
    color: #25d366;
  }

  &:nth-of-type(2) {
    background-color: ${lighten(0.43, '#0084FF')};
    color: #0084ff;
  }

  &:nth-of-type(3) {
    background-color: ${lighten(0.43, '#FFB400')};
    color: #ffb400;
  }
`;

type InviteLinkProps = {
  inviteURL: string;
};

const InviteLinkSocials = ({ inviteURL }: InviteLinkProps) => {
  const openWhatsApp = () => {
    window.open(
      `https://wa.me/?text=${encodeURIComponent(
        `${inviteURL} ${INVITE_MESSAGE}`,
      )}`,
      '_blank',
      'noopener,noreferrer',
    );
  };

  const openMail = () => {
    window.open(
      `mailto:?subject=${encodeURIComponent(
        'Uitnodiging Belmondofoto | Doe je mee?',
      )}&body=${encodeURIComponent(`${inviteURL} ${INVITE_MESSAGE}`)}`,
      '_blank',
      'noopener,noreferrer',
    );
  };

  // const openMessenger = () => {
  //   const messengerShareUrl = `https://www.facebook.com/dialog/send?link=${encodeURIComponent(
  //     `${inviteURL} ${INVITE_MESSAGE}`
  //   )}&app_id=1739239850199539&redirect_uri=${encodeURIComponent(
  //     window.location.href
  //   )}`;
  //   window.open(messengerShareUrl, '_blank');
  // };

  return (
    <ActionsContainer>
      <StyledButton onClick={openWhatsApp}>
        <FontAwesomeIcon icon={faWhatsapp} />
      </StyledButton>
      {/* <StyledButton onClick={openMessenger}>
        <FontAwesomeIcon icon={faFacebookMessenger} />
      </StyledButton> */}
      <StyledButton onClick={openMail}>
        <FontAwesomeIcon icon={faEnvelope} />
      </StyledButton>
    </ActionsContainer>
  );
};

export default InviteLinkSocials;
