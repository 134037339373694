import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import styled from 'styled-components';

import { INVITE_MESSAGE } from '@constants/invite-message';
import { maxWidth } from '@constants/media-queries';
import Button from '@elements/Button';

import DropdownWrapper from './DropdownWrapper';
import Notification from './Notification';

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledDropdownWrapper = styled(DropdownWrapper)`
  ${maxWidth.mobile} {
    width: 100%;
  }
`;

type InviteLinkProps = {
  inviteURL: string;
  type: 'co-owner' | 'participant';
  buttonStyle?: 'primary' | 'tertiary';
  [x: string]: any;
};

const InviteLink = ({
  inviteURL,
  type,
  buttonStyle = 'tertiary',
  ...rest
}: InviteLinkProps) => {
  const duration = 3000;
  const [shown, setShown] = useState(false);

  const copyTextToClipboard = () => {
    setShown(true);
    if (!navigator.clipboard) return;
    navigator.clipboard.writeText(`${inviteURL} ${INVITE_MESSAGE}`).then(
      () => {
        setTimeout(() => {
          setShown(false);
        }, duration);
      },
      (error) => {
        console.error('Async: Could not copy text: ', error);
      },
    );
  };

  return (
    <StyledDropdownWrapper
      dropdownChildren={shown ? <Notification text="Link gekopieerd!" /> : null}
      position="top"
      align="right"
      showMode="onClick"
    >
      <StyledButton
        onClick={copyTextToClipboard}
        onAnimationEnd={() => setShown(false)}
        data-gtm="share_book"
        iconLeft={faLink}
        set={buttonStyle}
        {...rest}
      >
        Kopieer de link
      </StyledButton>
    </StyledDropdownWrapper>
  );
};

export default InviteLink;
