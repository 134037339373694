export const books = [
  {
    id: 1,
    title: '21 Diner',
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/book-carousel/21%20Diner%20Fien_-LC2nGvQXR.png?updatedAt=1734079829987&ik-s=e2c0ca92dc619f97ec668075dbcaa269c92e1650',
    color: '#C4A3C2',
  },
  {
    id: 2,
    title: 'Papa 65',
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/book-carousel/De%20beste%20papa_BdEMkeh4N.png?updatedAt=1734079830163&ik-s=099bf2bece3a1c6d50329a82b54039931827a29c',
    color: '#d0b8ac',
  },
  {
    id: 3,
    title: 'Verjaardag 25',
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/book-carousel/25%20Jaar%20Robin_cover.png?updatedAt=1734079991210&ik-s=373fd7584d9771ec28afb37dafe0b751ebf1b9ca',
    color: '#8DBCA8',
  },
  {
    id: 4,
    title: 'Collega',
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/book-carousel/Collegaboek_4y7MxQiBO.png?updatedAt=1734079830290&ik-s=8cfc6516ddea919c7bc2eec6d3619c525f6c87ba',
    color: '#F0B248',
  },
  {
    id: 5,
    title: 'Verjaardag 40',
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/book-carousel/40%20jaar%20Lieke_yRj8Z55DQ.png?updatedAt=1734079829918&ik-s=a2dc7cd4300747a27f54bc170f081bc02736cd4d',
    color: '#F47F7A',
  },
  {
    id: 6,
    title: 'Jubileum',
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/book-carousel/60%20jaar%20jubileum_f4o6Hd2kf.png?updatedAt=1734079830202&ik-s=7a6fafc171d6e883c799c3a7414ac283e364226c',
    color: '#ea9ab2',
  },
  {
    id: 7,
    title: 'Recepten',
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/book-carousel/Receptenboek_5-8_Mto4Q.png?updatedAt=1734079830453&ik-s=0162cd557c5b2de17ca58747dd61d6742f685d9c',
    color: '#8DBCA8',
  },
  {
    id: 8,
    title: 'Pensioen',
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/book-carousel/Gijs%20bedankt_LLxHXFYnJ.png?updatedAt=1734079830281&ik-s=8f26caa90efc4f7c48c53b56080ff03348e5672d',
    color: '#99c1de',
  },
  {
    id: 9,
    title: 'Verjaardag 50',
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/book-carousel/50%20jaar%20Peter_9NTQhnav3.png?updatedAt=1734096021944&ik-s=e7d637ad6d7b6fe576097dc6fd433b9ca006fc6a',
    color: '#FF9F5F',
  },
  {
    id: 10,
    title: 'Verjaardag 30',
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/book-carousel/Sara%2030_9Sq70ssx8.png?updatedAt=1734082334022&ik-s=d764208a877b89fcd6f16f5aa55f77b1c13acd6d',
    color: '#d0b8ac',
  },
  {
    id: 11,
    title: 'Trouwen',
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/book-carousel/belmondofoto%20cover%20trouwen%20alt2_TY-LEEPtu.png?updatedAt=1737027364493&ik-s=ac3ea64d1840f007df7f59d01719bf801325814d',
    color: '#8DBCA8',
  },
  {
    id: 12,
    title: 'Herinnering',
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/book-carousel/Herinnering%20Jan_OhmPIPEBn.png?updatedAt=1734079830880&ik-s=73b7820b64de8972d5302bd509ab9e03a53b2492',
    color: '#759AD9',
  },
  {
    id: 13,
    title: 'Wintersport',
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/book-carousel/belmondofoto%20cover%20wintersport%20alt_cgvbGFM1X.png?updatedAt=1736955847475&ik-s=584a88af0a0aefcc7e1a973578f54140c1b5155c',
    color: '#FF8956',
  },
];
